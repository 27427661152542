import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ZenIconsEnum} from "../../_enums/zen-icons.enum";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {animate, state, style, transition, trigger} from "@angular/animations";

export enum MessageType {
  INFO = 'info', ERROR = 'error', SUCCESS = 'success'
}

export interface MessageListModel {
  label: string;
  value: string | number;
  command?: Function;
  type?: 'file' | 'yes_no';
}

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [
      CommonModule
  ],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss',
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({ height: '45px', overflow: 'hidden'})),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('collapsed <=> expanded', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class MessageComponent implements OnInit {
  @Input() type = MessageType.INFO;
  @Input() message: string;
  @Input() messageListData: MessageListModel[];
  @Input() enableShowMore: boolean;
  @Input() icon: ZenIconsEnum = ZenIconsEnum.INFO;
  safeHtmlMsg: SafeHtml;
  showMore: boolean;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    if (this.message) {
      this.safeHtmlMsg = this.sanitizer.bypassSecurityTrustHtml(this.message);
    }
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
  }

  protected readonly ZenIconsEnum = ZenIconsEnum;
}
