<div class="mp-message-main" [ngClass]="type"
     [class.enable-show-more]="enableShowMore"
     [@expandCollapse]="showMore ? 'expanded' : 'collapsed'">
    <span *ngIf="enableShowMore" class="show-more-btn" (click)="toggleShowMore()">
        {{ showMore ? 'Show less' : 'Show more' }}
        <i class="material-symbols-rounded">{{ showMore ? ZenIconsEnum.DOWN_ARROW : ZenIconsEnum.RIGHT_ARROW }}</i>
    </span>
    <div class="d-flex">
        <i class="material-symbols-rounded msg-icon">{{ icon }}</i>
        <span class="message-text text-start" [innerHTML]="safeHtmlMsg"></span>
    </div>

    <ul class="my-2 ms-3" *ngIf="messageListData?.length">
        <ng-container *ngFor="let d of messageListData">
            <li *ngIf="d.value">
                <div class="d-flex align-items-center">
                    <span class="me-2">{{ d?.label }}</span>

                    <ng-container *ngIf="d.type === 'file'">
                        <span class="font-weight-bold d-flex align-items-center pointer" (click)="d.command ? d.command() : null">
                            <i class="material-symbols-rounded mx-1 medium">{{ ZenIconsEnum.DOWNLOAD }}</i>
                            {{ d?.value }}
                        </span>
                    </ng-container>

                    <ng-container *ngIf="d.type !== 'file'">
                        <span class="font-weight-bold">"{{ d?.value }}"</span>
                    </ng-container>
                </div>
            </li>
        </ng-container>
    </ul>

</div>
