import {RateCheckStatusEnum} from "../_enums/rate-check-status";
import {CustomerEntity} from "./customer-entity.model";
import {MarketplaceRequestDocumentType} from "../_enums/marketplace-request-document-type.enum";
import {UnitEnum} from "../_enums/unit.enum";
import {UnitDto} from "./full-quote-request-scenario.model";

export interface DealDeskSummaryDto {
    quoteRequest: QuoteRequest;
    customer: CustomerEntity;
    contractDetails: DealDeskContractDetails;
    rateCheckDetails: DealDeskRateCheckDetails;

    state: string;
    country: CountryEnum;
    commodityType: CommodityType;

    supplierSetupType: SupplierSetupType;
    contractSubmittedDt: Date;

    annualUsage: number;
    unitAnnualUsage: number;
    meterCount: number;
    legalEntities: LenIdAndName[];

    incumbentSupplier: boolean;

    taxExempt: boolean;
    renewal: ContractRenewalEnum;
    sweetSpots: boolean;

    // Product Details
    productType: string; // TODO: need to implement this string value

    pricingDt: Date;
    quoted: boolean;
    dealDeskStatus: DealDeskStatus;
    utilities: UtilityIdAndName[];
    daysSinceBooking: number;
    dealDeskBiddingSubStatus: DealDeskBiddingSubStatusEnum;
    dealDeskAwaitingSignatureSubStatus: DealDeskAwaitingSignatureSubStatusEnum;
    unit: UnitEnum;
}

export interface UtilityIdAndName {
    id: number;
    name: string;
}

export interface DealDeskRateCheckDetails {
    csa: boolean;
    lastLookExpirationDt: Date;
    rateCheckId: string;
    rateCheckIdShort: string;
    rateCheckRefreshId: string;
    rateCheckStatusCode: RateCheckStatusEnum;
    rateCheckType: RateCheckType;
    specialRequirements: string;
    startingTerms: number[];
    requestDueDate: Date;
    bidCloseTime: Date;
    txMatrixSwitchData: boolean;
    matrixPricing: boolean;
    totalFee: number; // brokerFee
    productConfiguration: ProductConfiguration;
    renewableQuotes: RenewableQuotes;
    productName: string;
    productDescription: string;
}

export interface DealDeskContractDetails {
    energyPlanId: number;
    startDate: Date;
    endDate: Date;
    termMonths: number;
    supplierId: string;
    productConfiguration: ProductConfiguration;
    documents: RateCheckContractDocumentDto[];
    rate: number;
    staggeredStart: boolean;
    unitDto: UnitDto;
    productName: string;
    productDescription: string;
}

export interface RateCheckContractDocumentDto {
    rateCheckId: string;
    contractSignature: ContractSignature;
    documentCreateDt: Date;
    documentId: number;
    documentType: MarketplaceRequestDocumentType;
    downloadUrl: string;
    fileName: string;
    originalFileName: string;
}

export interface ContractSignature {
    firstName: string;
    lastName: string;
    signStatus: SignatureStatus;
    userId: string;
    signatureId: number;
    signatureImageId: number;
    signatureDt: Date;
}

export enum SignatureStatus {
    SIGNED = 'signed',
    PENDING = 'pending'
}

export interface DealDeskSummaryDtoTableRow extends DealDeskSummaryDto {
    companyName?: string;
    lenNames?: string[];
    rateCheckIdShort?: string;
    rateCheckType?: RateCheckType;
    annualUsageText?: string;
    requestDueDate?: Date;
    requestDueDateFormatted?: string;
    bidCloseTimeFormatted?: string;
    statusText?: string;
    actionHtml?: string;
    productDetailsHtml?: string;
    contractTypeHtml?: string;
    contractSubmittedDtFormatted?: string;
    creditStatusHtml?: string;
    daysSinceBookingHtml?: string;
    startDateFormatted?: string;
    startDate: Date; // to support sorting
    termMonths?: number;
}

export enum BillingMethod {
    dual = 'dual', consolidated = 'consolidated', bundled = 'bundled'
}

export enum DealDeskBiddingSubStatusEnum {
    OPEN = 'OPEN',
    SUBMITTED = 'SUBMITTED',
    CLOSED = 'CLOSED',
    LAST_LOOK = 'LAST_LOOK'
}
export enum DealDeskAwaitingSignatureSubStatusEnum {
    INTERNAL_REVIEW = 'INTERNAL_REVIEW',
    CUSTOMER_REVIEW = 'CUSTOMER_REVIEW',
}
export interface LenIdAndName {
    id: string;
    name: string;
}

export interface ProductConfiguration {
    // Base
    renewableName: string;
    billingMethod: BillingMethod;

    basis: FixedPassthroughEnum;
    commodityType: CommodityType;
    energy: FixedPassthroughEnum;
    paymentTerms: PaymentTerm;
    renewable: boolean; // For EP this is true if greenPct > 0
    swing: number;

    sustainability: Sustainability;

    // Electric
    capacity: AdjustablePassthroughEnum;
    transmission: AdjustablePassthroughEnum;

    // Gas
    basisPricingLocation: BasisPricingLocation;

    optOutLanguage: OptOutLanguage;
}

export interface OptOutLanguage {
    included: boolean;
    aoba: boolean;
}

export enum FixedPassthroughEnum {
    FIXED = 'FIXED', PASSTHROUGH = 'PASSTHROUGH'
}

export enum AdjustablePassthroughEnum {
    FIXED_NONADJUSTABLE = 'FIXED_NONADJUSTABLE', FIXED_ADJUSTABLE = 'FIXED_ADJUSTABLE', PASSTHROUGH = 'PASSTHROUGH'
}

export interface Sustainability {
    included: boolean;
    type: SustainabilityTypeEnum;
    percentage: number;
    certified: boolean;
    state: string;
    source;
}

export enum SustainabilityTypeEnum {
    RENEWABLE_ENERGY_CREDITS = 'RENEWABLE_ENERGY_CREDITS', CARBON_OFFSETS = 'CARBON_OFFSETS'
}

export enum RenewableQuotes {
    yes = 'yes', no = 'no', both = 'both'
}

export enum BasisPricingLocation {
    BURNER_TIP = 'burner tip',
    CITY_GATE = 'city gate'
}

export enum CommodityType {
    ELECTRICITY = 'ELECTRICITY', GAS = 'GAS', ALL = 'ALL'
}

export enum PaymentTerm {
    NET_15 = ('Net 15'), NET_20 = ('Net 20'), NET_30 = ('Net 30')
}

export enum RateCheckType {
    MATRIX = 'Matrix',
    CUSTOM = 'Custom'
}

export interface CreditApproval {
    status: CreditStatus;
    reason: string;
}

export enum CreditStatus {
    PENDING = 'PENDING', APPROVED = 'APPROVED', REJECTED = 'REJECTED'
}

export enum SupplierSetupType {
    NEW_SET_UP = 'New Set Up',
    REFRESH = 'Refresh'
}

export enum CountryEnum {
    US = 'United States',
    CA = 'Canada',
    GB = 'United Kingdom'
}

export enum DealDeskStatus {
    BIDDING = 'BIDDING',
    CUSTOMER_DECIDING = 'CUSTOMER_DECIDING',
    CONTRACT_REQUESTED = 'CONTRACT_REQUESTED',
    AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
    AWAITING_BOOKING_CONFIRM = 'AWAITING_BOOKING_CONFIRM',
    AWAITING_COUNTERSIGN = 'AWAITING_COUNTERSIGN',
    BOOKED = 'BOOKED'
}

export interface DealDeskStageCountsDto {
    count: number;
    status: DealDeskStatus;
}

export interface QuoteRequest {
    // The quote request ID
    id: string;
    // The supplier rep ID to which the request was sent
    supplierRepId: string;
    createDt: Date;
    optedOut: boolean;
    billingMethod: BillingMethod;
    creditApproval: CreditApproval;
    invitedForLastLook: boolean;
}

export enum ContractRenewalEnum {
    YES, NO, PARTIAL
}
